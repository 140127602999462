import { Controller } from 'stimulus';

function showTermsErrorMsg(element, error_msg) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName != 'DIV') {
    element.classList.add('is-invalid');
    $(parentElement).append(
      `<div class="invalid-feedback">${error_msg}</div>`
    );
  }
}

function clearTermsErrorMsg(element) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName == 'DIV') {
    element.classList.remove('is-invalid');
    parentElement.removeChild(element.parentNode.lastChild);
  }
}


export default class extends Controller {
  static targets = ['termsInput', 'fileInput', 'buttonText'];

 connect() {
    this.fileInputTarget.addEventListener('change', () => {
      const fileName = this.fileInputTarget.files[0].name;
      const shortName = fileName.length > 24 ? `${fileName.slice(0, 20)}...${fileName.slice(-4)}` : fileName;
      this.buttonTextTarget.textContent = shortName + ' (click to change)';
    });
  }

  toggleTermsAcceptance(event) {
    if (event.currentTarget.checked) {
      clearTermsErrorMsg(this.termsInputTarget);
    }
  }
}
