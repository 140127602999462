import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'expertiseSkills', 'languageSkills'];

  connect() {
    $(this.expertiseSkillsTarget).select2({
      placeholder: this.expertiseSkillsTarget.dataset.placeholder
    });

    $(this.languageSkillsTarget).select2({
      placeholder: this.languageSkillsTarget.dataset.placeholder
    });
  }

}
