import { Controller } from "stimulus";
import { Modal } from "bootstrap";

const packInputName = "company_purchase_form[pack_id]";
const paymentInputName = "company_purchase_form[payment_method]";

function notChecked(inputName) {
  return (
    document.querySelector('input[name="' + inputName + '"]:checked') == null
  );
}

function packInputCheck(packErrorMsg) {
  var checked = true;
  if (notChecked(packInputName)) {
    checked = false;
    $(packErrorMsg).show();
  } else {
    $(packErrorMsg).hide();
  }
  return checked;
}

function paymentMethodInputCheck(paymentMethodErrorMsg) {
  var checked = true;
  if (notChecked(paymentInputName)) {
    $(paymentMethodErrorMsg).show();
    checked = false;
  } else {
    $(paymentMethodErrorMsg).hide();
  }
  return checked;
}

function allFieldsChecked(packErrorMsg, paymentMethodErrorMsg) {
  var packChecked = packInputCheck(packErrorMsg);
  var paymentChecked = paymentMethodInputCheck(paymentMethodErrorMsg);
  return packChecked && paymentChecked;
}

function addStrongHtml(value) {
  return "<strong>" + value + "</strong>";
}

export default class extends Controller {
  static targets = [
    "packErrorMsg",
    "paymentMethodErrorMsg",
    "packPriceBox",
    "placeOrderButton",
    "placeOrderButtonSidebar",
    "purchaseBillingFormErrorMsg",
    "purchaseBillingModal",
    "purchaseForm",
  ];

  connect() {
    this.checkFirstPaymentMethodIfNeeded();
    this.showAndSubmitBillingInformationModalForm();
  }

  checkFirstPaymentMethodIfNeeded() {
    if(notChecked(paymentInputName)) {
      const firstPaymentTypeSelector = `input[type='radio'][name='${paymentInputName}']`;
      const firstPaymentType = document.querySelectorAll(firstPaymentTypeSelector)[0];
      firstPaymentType.checked = true;
    }
  }

  packSelected(event) {
    packInputCheck(this.packErrorMsgTarget);
    var element = event.currentTarget;
    $($(this.packPriceBoxTarget).find("#pack-title")[0]).html(
      element.dataset.packTitle
    );
    $($(this.packPriceBoxTarget).find("#pack-vat")[0]).html(
      element.dataset.packVat
    );
    $($(this.packPriceBoxTarget).find("#pack-price")[0]).html(
      element.dataset.packPrice
    );
    $($(this.packPriceBoxTarget).find("#pack-vat-price")[0]).html(
      addStrongHtml(element.dataset.packVatPrice)
    );
    $($(this.packPriceBoxTarget).find("#pack-total-price")[0]).html(
      addStrongHtml(element.dataset.packTotalPrice)
    );
    $(this.packPriceBoxTarget).show();
  }

  paymentMethodSelected(_event) {
    paymentMethodInputCheck(this.paymentMethodErrorMsgTarget);
  }

  place_order(event) {
    event.preventDefault();
    if (
      allFieldsChecked(
        this.packErrorMsgTarget,
        this.paymentMethodErrorMsgTarget
      )
    ) {
      this.purchaseFormTarget.requestSubmit();
      this.disableButtons();
    }
  }

  disableButtons() {
    this.placeOrderButtonTarget.classList.add("disabled");
    this.placeOrderButtonSidebarTarget.classList.add("disabled");
    this.showSpinner();
  }

  showSpinner() {
    const spinner = `<div class="d-flex justify-content-center" style="padding: 0.25rem 1.5rem;">
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
      </div>
      </div>`;

    this.placeOrderButtonTarget.innerHTML = spinner;
    this.placeOrderButtonSidebarTarget.innerHTML = spinner;
    this.disableProductInputs();
  }

  disableProductInputs() {
    const inputs = document.querySelectorAll(`input[name='${packInputName}']`);

    inputs.forEach((input) => {
      input.disabled = true;
    })
  }

  showAndSubmitBillingInformationModalForm() {
    const billingInfoValid = this.purchaseFormTarget.getAttribute('data-billing-info-valid');
    if(billingInfoValid === 'true' || billingInfoValid === null) {
      return;
    }

    const element = document.getElementById("billingInformation");
    if(element) {
      this.show(this.purchaseBillingFormErrorMsgTarget);
      new Modal(element).show();
    }
  }

  show(element) {
    element.style.display = "block";
  }
}
