import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["previewModal"];

  show(e) {
    e.preventDefault();

    $.ajax({
      url: e.target.dataset["url"],
      success: (data) => {
        const body = this.previewModalTarget.querySelector(".modal-body");
        body.innerHTML = data;

        const modal = new Modal(this.previewModalTarget);
        modal.show();
      },
    });
  }
}
