import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['noGreenForm'];

  initialize() {
    let showGreen = this.noGreenFormTarget.dataset['employmentAgencyInGreen'];
    Array.from(
      $(this.noGreenFormTarget).find('.form-check-input')
    ).forEach((item, _index) => {
      if (showGreen != 'true') { $(item).removeClass("is-valid"); }
    });
  }

}
