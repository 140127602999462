// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "rails-ujs";
Rails.start();

// make available to .erb files
import Cookies from "js-cookie";
global.$ = $;
global.Cookies = Cookies;

import "bootstrap";
import "select2";
import './_tooltip';
import './_trix';
import "controllers";
