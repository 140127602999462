import { Controller } from 'stimulus';

function showTermsErrorMsg(element, error_msg) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName != 'DIV') {
    element.classList.add('is-invalid');
    $(parentElement).append(
      `<div class="invalid-feedback">${error_msg}</div>`
    );
  }
}

function clearTermsErrorMsg(element) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName == 'DIV') {
    element.classList.remove('is-invalid');
    parentElement.removeChild(element.parentNode.lastChild);
  }
}

export default class extends Controller {
  static targets = ['termsInput'];

  submitSocialLogin(event) {
    if (!this.termsInputTarget.checked) {
      event.preventDefault();
      event.stopPropagation();
      showTermsErrorMsg(this.termsInputTarget, this.termsInputTarget.dataset.error);
      this.termsInputTarget.parentElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    }
  }

  toggleTermsAcceptance(event) {
    if (event.currentTarget.checked) {
      clearTermsErrorMsg(this.termsInputTarget);
    }
  }
}
