import { Controller } from "stimulus";
import autocomplete from "autocompleter";
import Fuse from "fuse.js";

export default class extends Controller {
  static targets = ["keywordsInput"];

  keywordsIndex;
  autocompleteKeywords;

  initialize() {
    this.fetchKeywords();
  }

  connect() {
    this.buildAutocompleteKeywords();
  }

  disconnect() {
    this.autocompleteKeywords.destroy();
  }

  buildAutocompleteKeywords() {
    this.autocompleteKeywords = autocomplete({
      input: this.keywordsInputTarget,
      disableAutoSelect: true,
      fetch: async (text, update) => {
        const results = this.keywordsIndex.search(text, { limit: 10 });
        update(results.map(({ item }) => ({ label: item, value: item })));
      },
      onSelect: (item) => {
        this.keywordsInputTarget.value = item.label;
      },
    });
  }

  async fetchKeywords() {
    const response = await fetch("/autocomplete/keywords.js");
    const keywords = eval(await response.text());

    this.keywordsIndex = new Fuse(keywords);
  }
}
