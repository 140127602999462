import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["multiCheckBoxField"];
  static values = {
    tooManyOptions: String,
  };

  submit(event) {
    this.removeErrorMessages();
    for (const multiCheckbox of this.multiCheckBoxFieldTargets) {
      if (this.checkedInputs(multiCheckbox).length > 5) {
        this.createErrorMessage(multiCheckbox);
        window.scrollTo({ top: 0, behavior: "smooth" });
        event.preventDefault();
      }
    }
  }

  createErrorMessage(container) {
    const div = document.createElement("div");
    const text = document.createTextNode(this.tooManyOptionsValue);

    div.appendChild(text);
    div.classList.add("invalid-feedback");

    container.insertAdjacentElement("beforeend", div);
  }

  removeErrorMessages() {
    document.querySelectorAll(".invalid-feedback").forEach((e) => e.remove());
  }

  checkedInputs(container) {
    return container.querySelectorAll('input[type="checkbox"]:checked');
  }
}
