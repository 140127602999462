import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';
import { anySelected } from '../utils/checkboxes';
import { createForm, cleanUnsubmittedForms } from '../utils/bulkActionForms';

export default class extends Controller {
  static targets = [
    'checkbox',
    'searchForm',
    'modalDeleteBulkTemplate',
    'modalNoJobOffersSelected'
  ]

  static values = {
    updateDateUrl: String,
    activateUrl: String,
    deactivateUrl: String,
    deleteUrl: String
  }

  toggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
  }

  postAction(event) {
    const action = event.target.options[event.target.selectedIndex].value

    cleanUnsubmittedForms('.form-bulk-action')

    if (!anySelected(this.checkboxTargets)) {
      if (action !== 'update_all') {
        const modal = new Modal(this.modalNoJobOffersSelectedTarget)

        this.resetSelect(event.target)

        return modal.toggle()
      }
    }

    if (action === 'update_all') {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = true
      })
    }

    const actionForm = createForm(this.setURL(action), this.checkboxTargets, this.searchFormTarget)

    if (action === 'delete') {
      const templateContent = this.modalDeleteBulkTemplateTarget.content.cloneNode(true)
      actionForm.appendChild(templateContent)

      const modal = new Modal(document.getElementById('modalDeleteBulk'))

      this.resetSelect(event.target)
      modal.toggle()
    } else {
      actionForm.submit()
    }
  }

  setURL(action) {
    switch (action) {
      case 'activate':
        return this.activateUrlValue
      case 'deactivate':
        return this.deactivateUrlValue
      case 'delete':
        return this.deleteUrlValue
      default:
        return this.updateDateUrlValue
    }
  }

  resetSelect(select) { select.selectedIndex = 0 }
}