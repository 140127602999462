import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { autoClose: Boolean }

  connect() {
    if (this.autoCloseValue) {
      setTimeout(() => {
        this.close();
      }, 3000);
    }
  }

  close(event) {
    const alertWrapper = event ? event.currentTarget.closest('#alert-wrapper') : this.element.closest('#alert-wrapper');
    if(event) {
      alertWrapper.remove();
    } else {
      const alertElement = alertWrapper.querySelector('.alert');
      alertElement.classList.remove('show');
      setTimeout(() => {
        alertWrapper.remove();
      }, 300);
    }
  }

}
