import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.element.dataset['action'] = 'submit->disable-submit-on-click#disableForm'

    if (!this.hasWithValue) {
      this.withValue = this.element.dataset['disableWithValue']
    }
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }

}
