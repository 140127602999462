import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'truncDescription', 'fullDescription' ]

  showMoreDescription(event) {
    if (this.truncDescriptionTarget.classList.contains('hidden')) {
      $(this.fullDescriptionTarget).slideToggle({
        duration: 400,
        complete: () => {
          $(this.truncDescriptionTarget).toggleClass('hidden');
        }
      });
      event.currentTarget.innerHTML = event.currentTarget.dataset.readmore;
    } else {
      $(this.fullDescriptionTarget).slideToggle({
        duration: 400,
        start: () => {
          $(this.truncDescriptionTarget).toggleClass('hidden');
        }
      });
      event.currentTarget.innerHTML = event.currentTarget.dataset.readless;
    }
  }

}
