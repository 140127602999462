import { Tooltip } from 'bootstrap';

$(document).ready(function () {
  const tooltipClass = (el) => {
    if (el.getAttribute('data-bs-tooltip-class')) {
      return el.getAttribute('data-bs-tooltip-class');
    } else if (el.classList.contains('form-control')) {
      return 'tooltip-form';
    } else {
      return 'tooltip-default';
    }
  };

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  tooltipTriggerList.map((el) => {
    return new Tooltip(el, {
      customClass: tooltipClass(el),
    });
  });
});
