import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.element.dataset['action'] = 'submit->disable-enable-submit-on-click#disableForm'
    this.element.dataset['action'] += ' ajax:success->disable-enable-submit-on-click#enableForm'
    this.element.dataset['action'] += ' ajax:error->disable-enable-submit-on-click#enableForm'

    if (!this.hasWithValue) {
      this.withValue = this.element.dataset['disableWithValue']
    }
  }

  disableForm(event) {
    this.submitButtons().forEach(button => {
      this.data.set('originalValue', button.innerHTML)
      button.disabled = true
      button.innerHTML = this.withValue
    })
  }

  enableForm(event) {
    this.submitButtons().forEach(button => {
      button.disabled = false
      button.innerHTML = this.data.get('originalValue')
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("button[type='submit']")
  }

}
