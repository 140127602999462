import { Controller } from "stimulus";
import autocomplete from "autocompleter";
import Fuse from "fuse.js";

export default class extends Controller {
  static targets = ["cityInput"];

  citiesIndex;
  autocompleteCities;

  initialize() {
    this.fetchCities();
  }

  connect() {
    this.buildAutocompleteCities();
  }

  disconnect() {
    this.autocompleteCities.destroy();
  }

  buildAutocompleteCities() {
    this.autocompleteCities = autocomplete({
      input: this.cityInputTarget,
      disableAutoSelect: true,
      fetch: async (text, update) => {
        const results = this.citiesIndex.search(text, { limit: 10 });
        update(results.map(({ item }) => ({ label: item, value: item })));
      },
      onSelect: (item) => {
        this.cityInputTarget.value = item.label;
      },
    });
  }

  async fetchCities() {
    const response = await fetch("/autocomplete/cities.js");
    const cities = eval(await response.text());

    this.citiesIndex = new Fuse(cities);
  }

}
