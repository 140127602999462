import { Controller } from "stimulus";
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = [ 'understoodButton' ];

  connect() {
    const $body = document.querySelector('body');
    const cookieName = 'infopraca_cookies_accepted';
    const bodyClass = 'cookies-show';

    if (!Cookies.get(cookieName)) {
      $body.classList.add(bodyClass);
    
      this.understoodButtonTarget.onclick = () => {
        $body.classList.remove(bodyClass);
        Cookies.set(cookieName, 'true', { expires: 365 });
      };
    }    
  };
}
