import { Controller } from "stimulus";

// DEBUG PURPOSES
function isPushNotificationsEnabledVerbose() {
  Promise.all([
          OneSignal.isPushNotificationsEnabled(),
          OneSignal.getUserId(),
          OneSignal.getRegistrationId(),
          OneSignal.getNotificationPermission(),
          OneSignal.isOptedOut(),
          OneSignal.context.serviceWorkerManager.getActiveState()
      ])
      .then(([isSubscribed, userId, registrationId, notificationPermission, optedOut, serviceWorkerActive]) => {
          console.log('Is Completely Subscribed:', isSubscribed);
          console.log('');
          console.log('What is our OneSignal user ID?', userId);
          console.log('What is our push subscription token?', registrationId);
          console.log('What is the notification permission status?', notificationPermission);
          console.log('Are you manually opted out?', optedOut);
          console.log("Is a service worker registered and active? (should be false on Safari, otherwise should be 'Worker A (Main)')?", serviceWorkerActive);
          console.log('What is the current URL of this page?', location.href);
          console.log("What environment does OneSignal think it's in?", OneSignal.sdkEnvironment.getWindowEnv());
      })
      .catch(e => {
          console.error("Issue determining whether push is enabled:", e);
      });
}

function oneSignalOptions(dataset) {
  let options = {
    cdn: true,
    OneSignalSDK: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
    appId: dataset.app_id,
    safari_web_id: dataset.safari_id,
    autoRegister: false,
    notifyButton: { enable: false },
    welcomeNotification: { disable: true },
    promptOptions: {
      autoPrompt: false,
      siteName: dataset.site_name,
      actionMessage: dataset.action_message,
      exampleNotificationTitle: dataset.example_notification_title,
      exampleNotificationMessage: dataset.example_notification_message,
      exampleNotificationCaption: dataset.example_notification_caption,
      acceptButtonText: dataset.accept_button_text,
      cancelButtonText: dataset.cancel_button_text,
      autoAcceptTitle: dataset.auto_accept_title
    }
  }
  let dev_subdomain = dataset.dev_subdomain;
  if ( dev_subdomain != undefined || dev_subdomain != '' ) {
    options['subdomainName'] = dev_subdomain
  }
  return options;
}

function pushPermissionsChanged(jobOfferId, permission) {
  if(permission === 'granted') {
    OneSignal.push(function() {
      OneSignal.getTags().then(function(tags) {
        if (!tags || tags.job_id === jobOfferId) {
          $('.web-push').addClass('hidden');
          $('.push-notification-feedback-msg').removeClass('hidden');
        } else {
          $('.web-push').removeClass('hidden');
        }
      })
    });
  } else {
    $('.web-push').removeClass('hidden');
  }
}

function initialize(dataset) {
  const jobOfferId = dataset.job_offer_id;

  OneSignal.push(function() {
    OneSignal.init(oneSignalOptions(dataset))
  });

  // If the user never subscribed before, add a job offer tag.
  OneSignal.push(function () {
    OneSignal.getUserId().then(function(userId) {
      if (!userId) {
        OneSignal.push(['sendTags', {job_id: jobOfferId}]);
      }
    })
  });

  OneSignal.push(['getNotificationPermission', function(permission) {
    if (permission === 'granted') {
      OneSignal.push(function () {
        OneSignal.getTags().then(function (tags) {
          if (tags && tags.job_id === jobOfferId) {
            $('.web-push').addClass('hidden');
          }
        })
      });
    }
  }]);

  OneSignal.push(function() {
    OneSignal.on('notificationPermissionChange', function (permissionChange) {
      pushPermissionsChanged(jobOfferId, permissionChange.to);
    })
  });
}

export default class extends Controller {
  static targets = [ 'oneSignalScript' ];
  static values = { showWebpushSubscriptionPrompt: Boolean };

  connect() {
    if(this.showWebpushSubscriptionPromptValue) {
      this.triggerOneSignalPrompt();
      }
  }

  triggerOneSignalPrompt() {
    window.OneSignal = window.OneSignal || [];
    const dataset = this.oneSignalScriptTarget.dataset;
    const jobOfferId = dataset.job_offer_id;

    window.OneSignal.push(function() {
      OneSignal.init(oneSignalOptions(dataset));

      OneSignal.push(function() {
        OneSignal.on('notificationPermissionChange', function(permissionChange) {
          var newPermission = permissionChange.to;
          if (newPermission === 'granted') {
            $('.web-push').addClass('hidden');
            $('.push-notification-feedback-msg').removeClass('hidden');
          }
        });

        OneSignal.on('popoverAllowClick', function() {
          OneSignal.push(['sendTags', {job_id: jobOfferId}]);
        });

        OneSignal.push(['getNotificationPermission', function (permission) {
          if (permission === 'default') {
            OneSignal.showSlidedownPrompt({force: true, slidedownPromptOptions: { autoPrompt: false }});
          }
        }]);
      });
    });
  }

  subscribeToJobOffer() {
    window.OneSignal = window.OneSignal || [];
    const dataset = this.oneSignalScriptTarget.dataset;
    const jobOfferId = dataset.job_offer_id;

    if (window.OneSignal.__initAlreadyCalled == false) {
      initialize(dataset);
    }

    OneSignal.push(['sendTags', {job_id: jobOfferId}]);

    OneSignal.push(['getNotificationPermission', function (permission) {
      if (permission === 'granted') {
        $('.web-push').addClass('hidden');
        $('.push-notification-feedback-msg').removeClass('hidden');
      } else {
        OneSignal.showSlidedownPrompt({force: true});
      }
    }]);
  }
}
