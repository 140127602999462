import { Controller } from 'stimulus';

function recordsSizeCheck(limit) {
  var recordsSize = $('.associationRow:visible').length;
  if (recordsSize >= limit) {
    $('.addMoreButton').hide();
  } else {
    $('.addMoreButton').show();
  }
}
export default class extends Controller {
  static targets = ['associationsTable']

  newAssociationIndex;
  maxRecords;

  connect() {
    this.newAssociationIndex = 0;
    this.maxRecords = parseInt(this.associationsTableTarget.dataset.maxRecords) || 0;

    recordsSizeCheck(this.maxRecords);
  }

  addAssociation(event) {
    event.preventDefault();

    var html = $(event.currentTarget).data('fields');
    html = html.replaceAll('NEW_RECORD', new Date().getTime() + this.newAssociationIndex);
    this.newAssociationIndex += 1;

    $(this.associationsTableTarget).append($('<div />').html(html).text());
    recordsSizeCheck(this.maxRecords);
  }

  deleteAssociation(event){
    event.preventDefault();

    var row = $(event.currentTarget.closest('.associationRow'));
    var new_record = $(event.currentTarget).data('new-record');

    if (new_record) {
      row.remove();
    } else {
      $(event.currentTarget).prev('input[type=hidden]').val('1')
      row.hide()
    }
    recordsSizeCheck(this.maxRecords);
  }
}
