import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["short", "full"]

  showMore(event) {
    event.preventDefault();
    this.shortTarget.classList.add('d-none');
    this.fullTarget.classList.remove('d-none');
  }
}
