import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  close() {
    const element = document.getElementById("billingInformation");
    const modal = Modal.getInstance(element);

    modal.hide();
    this.removeValidationErrors();
  }

  removeValidationErrors() {
    const inputs = document.querySelectorAll(".is-invalid");
    const errorMessages = this.element.querySelectorAll(".invalid-feedback");

    inputs.forEach((input) => {
      input.classList.remove("is-invalid");
      input.classList.add("is-valid");
    });

    errorMessages.forEach((message) => {
      message.remove()
    })
  }
}
