import Rails from 'rails-ujs';

import { Controller } from 'stimulus'

export default class extends Controller {

  submitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    
    const form = document.querySelector('#new_company_contact_form');
    Rails.fire(form, 'submit');
  }

}
