export function createForm(action, targets, searchForm) {
  const actionForm = document.createElement('form')

  actionForm.classList.add('form-bulk-action')
  actionForm.method = 'post'
  actionForm.action = action

  targets.forEach(checkbox => {
    if (checkbox.checked) {
      let formInput = document.createElement('input')
      formInput.setAttribute('name', checkbox.name)
      formInput.setAttribute('value', checkbox.value)
      actionForm.appendChild(formInput)
    }
  })

  addParameterValues(actionForm, searchForm)
  addAuthenticityToken(actionForm)

  document.body.appendChild(actionForm)

  return actionForm
}

export function cleanUnsubmittedForms(selector) {
  const unsubmittedForms = document.querySelectorAll(selector)

  unsubmittedForms.forEach(form => {
    form.remove()
  })
}

function addParameterValues(actionForm, searchForm) {
  const params = new FormData(searchForm)

  for (const param of params) {
    if (param[0] === 'authenticity_token') { continue }

    if (param[1] === '0' || param[1] === '') { continue }

    let formInput = document.createElement('input')
    formInput.setAttribute('type', 'hidden')
    formInput.setAttribute('name', param[0])
    formInput.setAttribute('value', param[1])
    actionForm.appendChild(formInput)
  }
}

function addAuthenticityToken(actionForm) {
  const meta = document.head.querySelector('meta[name="csrf-token"]')
  const token = meta ? meta.content : ''
  const formInput = document.createElement('input')

  formInput.setAttribute('type', 'hidden')
  formInput.setAttribute('name', 'authenticity_token')
  formInput.setAttribute('value', token)
  actionForm.appendChild(formInput)
}