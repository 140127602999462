import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ['busy']

  submitForm() {
    this.element.submit()
    this.element.classList.add(this.busyClass)
  }
}
