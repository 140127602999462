import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.element.dataset['action'] = 'click->disable-link-on-click#disableLink'
  }

  disableLink() {
    this.element.style.pointerEvents = 'none'
  }
}
