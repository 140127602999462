import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'secondPhoneNumber', 'provinceSelection' ];

  toggleSecondPhoneNumber(event) {
    if ($(this.secondPhoneNumberTarget).css("display") == "none") {
      $(this.secondPhoneNumberTarget).show();
      $(event.currentTarget).hide();
    }
  }

  checkSelectedCountry(event) {
    if (event.currentTarget.value == 'PL') {
      $(this.provinceSelectionTarget).show();
    } else {
      $(this.provinceSelectionTarget).hide();
    }
  }
}
