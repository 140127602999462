import { Controller } from 'stimulus';
import { Tooltip } from 'bootstrap';
import { Modal } from 'bootstrap';

const TRUE = 'true';
const FALSE = 'false';
const EMPTY = '';

function verifySelectedProfileLegalInfo() {
  let target = $('#companies_job_offer_form_profile')[0];
  if (target != undefined) {
    let hasProfileLegalInfo = target.selectedOptions[0].dataset.hasLegalInfo;
    let profileLegalInfoHtml = target.selectedOptions[0].dataset.legalInfoHtml;
    let updateProfileUrl = target.selectedOptions[0].dataset.updateProfileUrl;
    if (hasProfileLegalInfo == 'true') {
      $('#legal_info')[0].innerHTML = profileLegalInfoHtml;
      $('#legal_info').show();
      $('#not_legal_info_yet').hide();
    } else {
      $('#legal_info').hide();
      $('#edit-profile-link')[0].href = updateProfileUrl;
      $('#not_legal_info_yet').show();
    }
  }
}

function clearLocationInfo(element) {
  let elementKeyStarter = '#companies_job_offer_form_work_locations';
  let identifier = element.dataset.identifier;
  $(element).find(elementKeyStarter + '_destroy_' + identifier)[0].value = TRUE;
  $(element).find(elementKeyStarter + '_subregion_' + identifier)[0].value = EMPTY;
  $(element).find(elementKeyStarter + '_city_' + identifier)[0].value = EMPTY;
  $($(element).find(elementKeyStarter + '_use_external_url_' + identifier)[0]).prop('checked', false);
  $($(element).find(elementKeyStarter + '_url_' + identifier)[0]).closest('.work-location__url').value = EMPTY;
  $($(element).find(elementKeyStarter + '_url_' + identifier)[0]).closest('.work-location__url').hide();
}

function verifyAddMoreBtn(doc) {
  let anyHiddenToShow = $(doc).find('.work-location.work-location-hidden:first')[0];
  if (anyHiddenToShow == undefined) {
    $('.addNewLocationBtn').hide();
  }
}

function verifyCvRequired(target) {
  $('.work-location').each(function(_index) {
    let work_location = $(this).find('.work-location-checker')[0];
    if (work_location == undefined) { return; }

    if (target.checked) {
      $(this).find('.work-location-checker').show();
    } else {
      $(this).find('.work-location__url').hide();
      $(this).find('.work-location__url .url-input')[0].value = EMPTY;
      $(this).find('.work-location-checker').hide();
      $($(this).find('.work-location-checker .url-checker')[0]).prop('checked', false);
    }
  });
}

function showRequiredTooltip(inputField, requiredFieldMsg) {
  if (inputField.dataset['bsToggle'] == undefined) {
    if (inputField.classList.contains('select2-hidden-accessible')){
      new Tooltip($('.select2-container')[0], {title: requiredFieldMsg, customClass: 'tooltip-form'});
    } else {
      new Tooltip(inputField, {title: requiredFieldMsg, customClass: 'tooltip-form'});
    }
  }
}

function closeModal(modal) {
  if (modal != undefined) { modal.hide(); }
}

export default class extends Controller {
  static targets = [
    'JobOfferForm',
    'whatsappCheck',
    'whatsappFields',
    'cvRequiredCheck',
    'locationsBox',
    'addNewLocation',
    'categoriesSelector'
  ];

  removeQuestionsModal;

  connect() {
    verifySelectedProfileLegalInfo();
    verifyCvRequired(this.cvRequiredCheckTarget);
    verifyAddMoreBtn(document);
    this.whatsappChanged();

    $(this.categoriesSelectorTarget).select2();
  }

  profileChanged(_event) {
    verifySelectedProfileLegalInfo();
  }

  cvRequiredChanged(_event) {
    const offerWithQuestions = this.cvRequiredCheckTarget.dataset.offerWithQuestions;
    if (!this.cvRequiredCheckTarget.checked && offerWithQuestions == 'true') {
      this.removeQuestionsModal = new Modal(
        $('#removeQuestionsModal')[0],
        { focus: true, backdrop: 'static', keyboard: false }
      );
      this.removeQuestionsModal.show();
    }
    verifyCvRequired(this.cvRequiredCheckTarget);
  }

  checkTheCvRequiredInput(event) {
    event.preventDefault();

    $($(this.cvRequiredCheckTarget)[0]).prop('checked', true);
    closeModal(this.removeQuestionsModal);
  }

  whatsappChanged(_event) {
    if(!this.hasWhatsappCheckTarget) {
      return;
    }

    this.setupControlsBasedOnWhatsApp();
  }

  setupControlsBasedOnWhatsApp(event) {
    if(event!==undefined) {
      event.preventDefault();
    }
    const whatsappEnabled = this.whatsappCheckTarget.checked;

    this.whatsappFieldsTarget.style.display = whatsappEnabled ? 'block' : 'none';
    let whatsAppFields= this.whatsappFieldsTarget.querySelectorAll('input');
    whatsAppFields.forEach(input => input.required = whatsappEnabled);
  }

  hideQuestionsModal(event) {
    event.preventDefault();

    closeModal(this.removeQuestionsModal);
  }

  locationExternalUrlChanged(event) {
    let workLocation = $(event.currentTarget).closest('.work-location')[0];
    let workLocationURL = $(workLocation).find('.work-location__url')[0];
    let workLocationURLInput = $(workLocationURL).find('.url-input')[0];
    if (event.currentTarget.checked) {
      $(workLocationURLInput).prop("disabled", false);
      $(workLocationURLInput).prop("required", true);
      $(workLocationURL).show();
    } else {
      $(workLocationURL).hide();
      if (workLocationURLInput != undefined) {
        workLocationURLInput.value = EMPTY;
      }
      $(workLocationURLInput).prop("required", false);
      $(workLocationURLInput).prop("disabled", true);
    }
  }

  addLocation(event) {
    event.preventDefault();

    let element = $(document).find('.work-location.work-location-hidden:first')[0];
    if (element != undefined) {
      let identifier = element.dataset.identifier;
      $(element).find('#companies_job_offer_form_work_locations_destroy_' + identifier)[0].value = FALSE;
      $($(element).find('.work-location__location select')[0]).prop("disabled", false);
      $($(element).find('.work-location__city input')[0]).prop("disabled", false);
      $(element).toggleClass('work-location-hidden');
      $(element).show();

      verifyAddMoreBtn(document);
    }
  }

  removeLocation(event) {
    event.preventDefault();

    let element = event.currentTarget.closest('.work-location');
    $($(element).find('.work-location__location select')[0]).prop("disabled", true);
    $($(element).find('.work-location__city input')[0]).prop("disabled", true);
    $(element).toggleClass('work-location-hidden');
    $(element).hide();
    clearLocationInfo(element);
    this.locationsBoxTarget.appendChild(element);
    $('.addNewLocationBtn').show();
  }

  submitForm(event) {
    event.preventDefault();

    $('#companies_job_offer_form_save_as_draft')[0].value = FALSE;
    if (this.validateForm(this.JobOfferFormTarget)) {
      $('.submitForm').addClass("disabled");
      $('.submitDraftForm').addClass("disabled");
      $($(event.currentTarget).find('.img-loading')[0]).show();
      $($(event.currentTarget).find('.button-text')[0]).hide();
      this.JobOfferFormTarget.submit();
    }
  }

  submitDraftForm(event) {
    event.preventDefault();

    $('#companies_job_offer_form_save_as_draft')[0].value = TRUE;
    if (this.validateForm(this.JobOfferFormTarget)) {
      $('.submitForm').addClass("disabled");
      $('.submitDraftForm').addClass("disabled");
      $($(event.currentTarget).find('.img-loading')[0]).show();
      $($(event.currentTarget).find('.button-text')[0]).hide();
      this.JobOfferFormTarget.submit();
    }
  }

  validateForm() {
    let isValid = true;
    let fields = this.JobOfferFormTarget.querySelectorAll(
      'input:required:not(.trix-input), select:required, trix-editor[data-required]'
    );
    for (let field of fields) {
      if (!field.disabled && !field.value.trim()) {
        showRequiredTooltip(
          field,
          this.JobOfferFormTarget.dataset.requiredFieldMessage
        );
        field.focus();
        scroll(0, $(field).offset().top - 100);
        isValid = false;
        return isValid;
      }
    }

    return isValid;
  }
}
