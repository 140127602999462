import { Controller } from "stimulus";

export default class extends Controller {
  static values = { maximum: Number };

  connect() {
    this.submitButton = document.getElementById("submit-button");
  }

  count() {
    const value = this.element.value.length;
    const errorDisplayed = this.element.classList.contains("is-invalid");

    if (value <= this.maximumValue && errorDisplayed) {
      this.hideError();
    }

    if (value > this.maximumValue && !errorDisplayed) {
      this.showError();
    }
  }

  showError() {
    const message = this.cloneTemplate();

    this.element.classList.add("is-invalid");
    this.element.parentNode.appendChild(message);
    this.submitButton.disabled = true;
  }

  hideError() {
    const errorMessage = document.getElementById(
      `${this.element.id}-error-message`
    );

    this.element.classList.remove("is-invalid");
    errorMessage.remove();
    this.submitButton.disabled = false;
  }

  cloneTemplate() {
    const template = document.getElementById("error-message-template");
    const clone = template.content.cloneNode(true);
    const message = clone.getElementById("error-message");

    message.id = `${this.element.id}-error-message`;

    return message;
  }
}
