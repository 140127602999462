import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showMoreButton", "hiddenFilters"];

  showMore(event) {
    event.preventDefault();
    this.showMoreButtonTarget.style.display = "none";
    this.hiddenFiltersTarget.classList.add("show");
  }

  submitFilter(event) {
    const selectedFilter = event.target;
    const url = selectedFilter.checked
      ? selectedFilter.dataset.urlChecked
      : selectedFilter.dataset.urlUnchecked;

    location.assign(url);
  }
}
