import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';
import { anySelected } from '../../utils/checkboxes';
import { createForm, cleanUnsubmittedForms } from '../../utils/bulkActionForms';

let PDF_REQUESTS_COUNTER = 0;
let PDF_REQUESTS_MAX = 3;

function closeModal() {
  // Without this timeout the modal won't close after the new window is open and the .zip file download is complete
  setTimeout(() => {
    Modal.getInstance(document.getElementById('modalDownloadBulk')).hide()
  }, 1000)

}

function requestPdf(url, params) {
  PDF_REQUESTS_COUNTER += 1

  $.ajax({
    method: 'POST',
    url: url,
    data: { applications_ids: params },
    timeout: 3000,
    error: function () {
      console.log('error')
      $(document).find('.pdf-generation-error')[0].style = 'display: block;'
      closeModal()
    },
    statusCode: {
      200: function (data) {
        data = JSON.parse(data)
        $(document).find('.pdf-generation-error')[0].style = 'display: none;'
        closeModal()
        window.open(data['url'], '_blank')
      },
      202: function () {
        if (PDF_REQUESTS_COUNTER === PDF_REQUESTS_MAX) {
          $(document).find('.pdf-generation-error')[0].style = 'display: block;'
          closeModal()
        } else {
          setTimeout(function () {
            requestPdf(url, params)
          }, 3000)
        }
      }
    }
  })
}

export default class extends Controller {
  static targets = [
    'checkbox',
    'searchForm',
    'modalDeleteBulkTemplate',
    'modalNoApplicationsSelected',
    'modalDownloadBulk'
  ]

  static values = {
    inProcessUrl: String,
    finalistUrl: String,
    discardedUrl: String,
    deleteUrl: String,
    readUrl: String,
    unreadUrl: String,
    generatePdfUrl: String
  }

  toggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
  }

  postAction(event) {
    const action = event.target.options[event.target.selectedIndex].value

    cleanUnsubmittedForms('.form-bulk-action')

    if (!anySelected(this.checkboxTargets)) {
      const modal = new Modal(this.modalNoApplicationsSelectedTarget)
      this.resetSelect(event.target)
      return modal.toggle()
    }

    if (action === 'download_pdf') {
      const modal = new Modal(this.modalDownloadBulkTarget, { backdrop: 'static' })

      modal.toggle()
      requestPdf(this.generatePdfUrlValue, this.buildParams())
      this.uncheckAll()
      this.resetSelect(event.target)
    } else {
      const actionForm = createForm(this.setURL(action), this.checkboxTargets, this.searchFormTarget)

      if (action === 'delete') {
        const templateContent = this.modalDeleteBulkTemplateTarget.content.cloneNode(true)
        actionForm.appendChild(templateContent)

        const modal = new Modal(document.getElementById('modalDeleteBulk'))
        this.resetSelect(event.target)
        modal.toggle()
      } else {
        actionForm.submit()
      }
    }
  }

  setURL(action) {
    switch (action) {
      case 'in_process':
        return this.inProcessUrlValue
      case 'finalist':
        return this.finalistUrlValue
      case 'discarded':
        return this.discardedUrlValue
      case 'delete':
        return this.deleteUrlValue
      case 'read':
        return this.readUrlValue
      case 'unread':
        return this.unreadUrlValue
    }
  }

  uncheckAll() {
    const selectAll = document.getElementById('selectAll')

    selectAll.checked = false

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })
  }

  buildParams() {
    const params = []

    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        params.push(checkbox.value)
      }
    })

    return params
  }

  resetSelect(select) { select.selectedIndex = 0 }
}