addEventListener('trix-initialize', updateToolbars, { once: true });

addEventListener('trix-file-accept', function (_event) {
  e.preventDefault();
});

import Trix from 'trix';

Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

// https://github.com/basecamp/trix/issues/680
Trix.config.blockAttributes.default.tagName = "p"
Trix.config.blockAttributes.default.breakOnReturn = true

Trix.config.textAttributes.underline = {
  style: { "textDecoration": "underline" },
  inheritable: true,
  parser: (element) => {
    element.style.textDecoration === "underline";
  }
};

Trix.Block.prototype.breaksOnReturn = function(){
  const attr = this.getLastAttribute()
  const config = Trix.getBlockConfig(attr ? attr : "default")
  return config ? config.breakOnReturn : false
}

Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function(){
  if(this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
    return this.startLocation.offset > 0
  } else {
    return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false
  }
};

function updateToolbars(event) {
  const toolbars = document.querySelectorAll('trix-toolbar');
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((toolbar) => (toolbar.innerHTML = html));

  document.querySelectorAll('trix-editor').forEach(function (editor) {
    editor.style.height = '8em';
    editor.style['overflow-y'] = 'auto';
    editor.style['resize'] = 'both';
  });

  document.querySelectorAll('trix-editor').forEach((input) => {
    if (input.attributes['disabled'] != undefined) {
      input.editor.element.setAttribute('contentEditable', false);
      input.style.background = '#eee';
      input.style.color = 'rgb(102, 102, 102)';
    }
  });
}

function toolbarDefaultHTML() {
  const { lang } = Trix.config;

  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" title="underline" tabindex="-1">U</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
      </span>
    </div>
  `;
}
