import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'provinceSelection' ];

  checkSelectedCountry(event) {
    if (event.currentTarget.value == 'PL') {
      $(this.provinceSelectionTarget).show();
    } else {
      $(this.provinceSelectionTarget).hide();
    }
  }
}
