import { Controller } from 'stimulus';

function submitFilterForm(target) {
  target.submit();
}

export default class extends Controller {
  static targets = [ 'filtersForm' ];

  filtersKeyPresses(event) {
    if ($(event.currentTarget).is(":focus") == false) {
      submitFilterForm(this.filtersFormTarget);
    } else {
      if (event.keyCode == 13){
        submitFilterForm(this.filtersFormTarget);
      }
    }
  }

  submitForm(event) {
    if ($(event.currentTarget).is(":focus") == false) {
      submitFilterForm(this.filtersFormTarget);
    }
  }

  submitFormNoChecks(_event) {
    this.filtersFormTarget.submit();
  }
}
