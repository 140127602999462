import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static classes = ['busy']
  static targets = [ 'workForm', 'endDateMonth', 'endDateYear', 'busy' ];

  submitStudyForm(_event) {
    this.workFormTarget.submit();
  }

  toggleCurrentlyWorking(event) {
    let checked = event.currentTarget.checked;
    this.endDateMonthTarget.disabled = checked;
    this.endDateYearTarget.disabled = checked;

    if (checked == true) {
      $(this.endDateMonthTarget).val('');
      $(this.endDateYearTarget).val('');
    }
  }

  onPostSuccess(event) {
    let [_data, _status, xhr] = event.detail;
    this.workFormTarget.innerHTML = xhr.response;

    window.scrollTo(0, 0);
    var modal = new Modal(
      document.getElementById('modal'),
      { focus: true, backdrop: 'static', keyboard: false }
    );
    modal.show();
  }

  onPostError(event) {
    let [_data, _status, xhr] = event.detail;
    this.workFormTarget.innerHTML = xhr.response;

    window.scrollTo(0, 0);
  }
}
