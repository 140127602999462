import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'deletionComment'];

  clearDeletionReason (event) {
    if (event.currentTarget.checked == false) {
      this.deletionCommentTarget.innerHTML = '';
    }
  }

}
