import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];
  static values = {
    defaultTemplate: String,
    sendApplicationAnswer: String,
    applicationsAnswer: String
  };

  onOptionChange(event) {
    switch (event.target.value) {
      case "default":
        this.contentTarget.value = this.defaultTemplateValue;
        this.contentTarget.disabled = true;
        break;
      case "customized":
        this.contentTarget.value = this.applicationsAnswerValue;
        this.contentTarget.disabled = false;
        this.contentTarget.focus();
        break;
      case "custom_default":
        this.contentTarget.value = this.defaultTemplateValue;
        this.contentTarget.disabled = false;
        this.contentTarget.focus();
        break;
      default:
        this.contentTarget.value = ""
        this.contentTarget.disabled = true;
    }
  }
}
