import { Controller } from 'stimulus';

let PDF_REQUESTS_COUNTER = 0;
let PDF_REQUESTS_MAX = 3;

function showLoader(target){
  $(target).find('.icon-printer')[0].style = "display: none;";
  $(target).find('.img-loading')[0].style = "display: inline-block;";
}

function hideLoader(target){
  $(target).find('.icon-printer')[0].style = "display: inline-block;";
  $(target).find('.img-loading')[0].style = "display: none;";
}

function hideErrorAlert(){
  $(document).find('.pdf-generation-error')[0].style = 'display: none;';
  $(document).find('.purchase-alert-success')[0].style = 'display: block;';
}

function requestPdf(target){
  PDF_REQUESTS_COUNTER += 1;
  $.ajax({
    url: target.dataset.generatePdfUrl,
    dataType: 'JSON',
    timeout: 3000,
    error: function(){
      hideLoader(target);
    },
    statusCode: {
      200: function(data) {
        hideLoader(target);
        window.open(data['url'], '_blank');
        hideErrorAlert();
      },
      202: function() {
        if (PDF_REQUESTS_COUNTER === PDF_REQUESTS_MAX) {
          hideLoader(target);
          $(document).find('.pdf-generation-error')[0].style = 'display: block;';
          $(document).find('.purchase-alert-success')[0].style = 'display: none;';
        } else {
          setTimeout(function () {
            requestPdf(target);
          }, 3000);
        }
      }
    }
  });
}

export default class extends Controller {

  requestPdf(event){
    event.preventDefault();

    PDF_REQUESTS_COUNTER = 0;
    const target = event.currentTarget;
    showLoader(target);
    requestPdf(target);
  }

  hideAlertMsg(_event){
    hideErrorAlert();
  }
}
