import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {
  static classes = ['busy']
  static targets = [ 'studyForm', 'endDateMonth', 'endDateYear', 'busy' ];

  submitStudyForm (_event) {
    this.studyFormTarget.submit();
  }

  toggleCurrentlyEnrolled (event) {
    let checked = event.currentTarget.checked;
    this.endDateMonthTarget.disabled = checked;
    this.endDateYearTarget.disabled = checked;

    if (checked == true) {
      $(this.endDateMonthTarget).val('');
      $(this.endDateYearTarget).val('');
    }
  }

  onPostSuccess(event) {
    let [_data, _status, xhr] = event.detail;
    this.studyFormTarget.innerHTML = xhr.response;

    window.scrollTo(0, 0);
    var modal = new Modal(
      document.getElementById('modal'),
      { focus: true, backdrop: 'static', keyboard: false }
    );
    modal.show();
  }

  onPostError(_event) {
    let [_data, _status, xhr] = event.detail;
    this.studyFormTarget.innerHTML = xhr.response;

    window.scrollTo(0, 0);
  }
}
