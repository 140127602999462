import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

function showInputErrorMsg(element, error_msg) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName != 'DIV') {
    element.classList.add('is-invalid');
    $(parentElement).append(
      `<div class="invalid-feedback">${error_msg}</div>`
    );
  }
}

function clearErrorMsg(element) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName == 'DIV') {
    element.classList.remove('is-invalid');
    parentElement.removeChild(element.parentNode.lastChild);
  }
}

function toggleReadMore(text_target, link_target) {
  $(text_target).slideToggle({
    duration: 400,
    complete: () => {
      $(text_target).toggleClass('hidden');

      if(text_target.classList.contains('hidden')) {
        link_target.innerText = link_target.dataset.readmore;
      } else {
        link_target.innerText = link_target.dataset.readless;
      }
    }
  });
}

export default class extends Controller {
  static targets = [
    'jobAlertModal',
    'emailInput',
    'termsInput',
    'createAlertForm',
    'marketingDescription',
    'showMarketingDescriptionBtn',
    'dataControlDescription',
    'showDataControlDescriptionBtn'
  ];

  connect() {
    if (this.jobAlertModalTarget.dataset.show == 'true') {
      this.showModalWithDelay();
    }
  }

  showModalWithDelay() {
    const delayDuration = 10000; // 10 seconds
    var modal = new Modal(this.jobAlertModalTarget, { focus: true });
    setTimeout(() => {
      modal.show();
    }, delayDuration);
  }

  submitCreateAlertForm(event) {
    let submitForm = true
    if (this.emailInputTarget.value == null || this.emailInputTarget.value == "" ) {
      event.preventDefault();
      event.stopPropagation();
      showInputErrorMsg(this.emailInputTarget, this.emailInputTarget.dataset.error);
      submitForm = false
    }
    if (!this.termsInputTarget.checked) {
      event.preventDefault();
      event.stopPropagation();
      showInputErrorMsg(this.termsInputTarget, this.termsInputTarget.dataset.error);
      submitForm = false
    }

    if (submitForm == false) {
      event.preventDefault();
    }
  }

  toggleEmailInput(event) {
    event.preventDefault();
    if (event.currentTarget.value != null || event.currentTarget.value != "" ) {
      clearErrorMsg(this.emailInputTarget);
    }
  }

  toggleTermsAcceptance(event) {
    event.preventDefault();
    if (event.currentTarget.checked) {
      clearErrorMsg(this.termsInputTarget);
    }
  }

  showMarketingDescription(_event) {
    toggleReadMore(this.marketingDescriptionTarget, this.showMarketingDescriptionBtnTarget);
  }

  showDataControlDescription(_event){
    toggleReadMore(this.dataControlDescriptionTarget, this.showDataControlDescriptionBtnTarget);
  }
}
